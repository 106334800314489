import { template as template_7654e390e036435eb1c5e5dc2ee09629 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7654e390e036435eb1c5e5dc2ee09629(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
