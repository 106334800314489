import { template as template_aba5c28e8be74b27b5c9e850c807fe4e } from "@ember/template-compiler";
const FKText = template_aba5c28e8be74b27b5c9e850c807fe4e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
